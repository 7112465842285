<template>
	<w-layout fill-height column>
		<w-flex fill-height scroll-y>
			<w-list>
				<w-list-tile v-for="serviceRequest in serviceRequests" :key="serviceRequest.id" @click="serviceRequest.id ? select(serviceRequest) : null">
					<TasksManagerTask :value="serviceRequest" class="pointer" />
				</w-list-tile>
			</w-list>
		</w-flex>
	</w-layout>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsServiceRequestList',
	components: {
		TasksManagerTask: () => ({
			component: import('@/components/TasksManager/TasksManagerTask')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			serviceRequests: []
		}
	},
	watch: {
		task: {
			deep: true,
			handler: function () {
				this.serviceRequests = [...this.task.children.filter(this.service.isOfTaskServiceRequestType)]
			},
			immediate: true
		}
	},
	methods: {
		select: function (serviceRequest) {
			this.service.selectTask(serviceRequest)
		}
	}
}
</script>
